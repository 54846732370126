<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="请选择" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		
		<h2>“Whistle” User Service Agreement</h2>
		
		<p>Welcome to use "Whistle" application software and related services! <br>
		In order to better provide you with services, please read the "Whistle" User Service Agreement (hereinafter referred to as "this Agreement") carefully. Before you start using the "Whistle" software and related services, please be sure to carefully read and fully understand this agreement, especially the terms involving exemption or limitation of liability, rights licensing and information use terms, and agreement to activate and use special individual services. Terms, applicable laws and dispute resolution provisions, etc. Among them, important contents such as exemption or limitation of liability clauses will be highlighted in bold and you should read them carefully. If you are under 18 years old, please be accompanied by a legal guardian to carefully read and fully understand this agreement, and obtain the consent of your legal guardian before using the "Whistle" software and related services. <br>
		Unless you fully accept all the contents of this Agreement, you have no right to download, install, register, log in, use (hereinafter collectively referred to as "use") the "Whistle" software, or use the "Whistle" service in any way, or obtain the "Whistle" Any services provided by the software
		(collectively referred to in this Agreement as "Use"). If you use "Whistle" software and related services, you are deemed to have fully understood this Agreement and promised to be bound by it as a party to this Agreement. <br>
		If you have any questions, comments or suggestions about the content of this Agreement, you can contact us by logging in to the "Feedback and Help" page in the "Whistle" client. </p>
		
		<h3>1. Scope of application</h3>
		<p>1.1 This agreement is between you and CLICK ADVERTISING & MEDIA PTE.
		LTD. (hereinafter referred to as "Company" or "We") for your downloading, installation, registration, login, use of "Whistle" software, and obtaining related services provided by "Whistle" software. The company has the right to unilaterally decide to arrange or designate its affiliates, controlling companies, successor companies or third-party companies recognized by the company to continue to operate the "Whistle" software based on the needs of the "Whistle" software and related services or operations. Furthermore, certain services involved under this Agreement may be provided to you by the Company's affiliates, controlling companies, successor companies or third-party companies recognized by the Company. You understand and agree that by accepting the relevant service content, you are deemed to have accepted the relevant rights and obligations and are also bound by this Agreement. <br>
		1.2 "User" refers to all users who directly or indirectly obtain and use the "Whistle" software and related services, including natural persons, legal persons and other organizations. Referred to as "User" or "You" in this Agreement.
		1.3 "Whistle" refers to the client application labeled "Whistle" and the company's website that is legally owned and operated by the company. The company has the right to unilaterally change the name of the application and website (including but not limited to name changes, additions, etc.).
		1.4 The content of this agreement also includes relevant agreements, rules, etc. regarding the "Whistle" software and related services that the company has released and may continue to release in the future (including the "Whistle" Privacy Policy). Once the aforementioned content is officially released, the company will remind you to read it in an appropriate manner (website announcement, system notification, etc.). The aforementioned relevant agreements and rules constitute an integral part of this Agreement, and you shall abide by them as well.
		</p>

		<h3>2. Use “Whistle” software and related services</h3>
		<p>
		2.1 When you use "Whistle" software and related services, you can obtain the "Whistle" client application or access Whistle-related websites through pre-installation or third-party downloads authorized by the company. If you do not obtain the software from the company or a third party authorized by the company, the company cannot guarantee that the unofficial version of the "Whistle" software can be used normally, and the company will have nothing to do with the losses you suffer as a result. <br>
		2.2 The company may develop different application software versions for different terminal devices. You should obtain, download and install the appropriate version according to the actual device conditions. <br>
		2.3 You can use the "Whistle" software and related services or update the "Whistle" version according to your own needs. If you no longer need to use the "Whistle" software and related services, you can uninstall it yourself. <br>
		2.4 In order to better improve user experience and services, the company will provide software updates or changes from time to time (including but not limited to software modifications, upgrades, function enhancements, development of new services, software replacement, etc.). In order to ensure the security of "Whistle" software and related services and improve user services, after the software and related services are updated or part of the service content is updated, where possible, the company will use methods including but not limited to system prompts, announcements, site messages, etc. The user is reminded that the user has the right to choose to accept the updated version or service. If the user does not accept it, some functions will be restricted or cannot be used anymore. <br>
		2.5 Unless expressly authorized in advance by the Company, you may not conduct any unauthorized access or use of the "Whistle" software and related services in any form, including but not limited to adaptation, copying, dissemination, vertical search, mirroring or trading. <br>
		2.6 You understand that when you use the "Whistle" software and related services, you need to prepare your own terminal equipment (such as computers, mobile phones, etc.) related to the software and related services. Once you open the "Whistle" software in your terminal equipment or access Whistle-related website, it is deemed that you are using "Whistle" software and related services. In order to fully realize all the functions of "Whistle", you may need to connect its terminal device to the Internet. You understand that you will bear the required fees (such as traffic fees, Internet fees, etc.). <br>
		2.7 The company grants you a personal, revocable, non-transferable, non-exclusive and non-commercial right to legally use the "Whistle" software and related services. All other rights not expressly authorized by this Agreement are still reserved by the Company. You must obtain additional written permission from the Company when exercising these rights. At the same time, the failure of the Company to exercise any of the aforementioned rights does not constitute a waiver of such rights. <br>
		2.8 You can start using the "Whistle" software and related services without registration. At the same time, you also understand that in order to enable you to better use the "Whistle" software and related services and protect the security of your account, certain functions and/or certain individual services, such as comment services, etc., require you to follow the national In accordance with the provisions of relevant laws and regulations, real-name registration and login can only be used after providing real identity information. <br>
		2.9 If you find any content in the "Whistle" software and website that infringes upon your rights, please immediately notify the company in accordance with the provisions of the Whistle infringement complaint window and provide preliminary evidence that you have relevant rights. The company will handle it in a timely manner in accordance with local laws and regulations. Your complaint. If the content you publish on "Whistle" is complained of infringement by the relevant rights holder, you can also lodge a complaint with the company by submitting a counter-notification and relevant proof of rights materials to the client, and the company will also handle your case in a timely manner in accordance with local laws and regulations. complaint.
		</p>
		
		<h3>3. About "Account"</h3>

		<p>3.1 "Whistle" provides users with a registration channel. Users have the right to choose legal character combinations as their own accounts and set their own passwords that meet security requirements. The account number and password set by the user are the credentials used by the user to log in and use the "Whistle" software and related services as a registered user. </p>
		<p>3.2 <strong>Account cancellation</strong><br>
		When you need to terminate the use of "Whistle" account services, you can apply to cancel your "Whistle" account if the following conditions are met:<br>
		(1) You can only apply to cancel your own account and follow the "Whistle" process;<br>
		(2) You should still bear the corresponding responsibility for your behavior before canceling your account and while using the "Whistle" service;<br>
		(3) After successful cancellation, account records, functions, etc. will not be restored or provided. <br>
		If you need to log out of your "Whistle" account, please open the Whistle client and follow the prompts to log out. </p>
		<p>3.3 You understand and promise that the account you set up must not violate national laws, regulations and the company's relevant rules. Your account name, avatar, profile and other registration information and other personal information must not contain illegal or harmful information. You are not allowed to open an account in another person's name (including but not limited to using another person's name, name, font size, avatar, etc. in a manner that is sufficient to cause confusion) with permission from others, and you are not allowed to maliciously register a "Whistle" account (including but not limited to frequent registration, batch registration) Account and other behaviors). You must abide by relevant laws and regulations during the registration and use of your account, and shall not carry out any behavior that infringes on national interests, harms the legitimate rights and interests of other citizens, or is harmful to social morals. The company has the right to review the registration information submitted by you. </p>
		<p>3.4 Your registered account in "Whistle" is only for your own use. Without the written consent of the company, you are prohibited from giving, borrowing, renting, transferring, selling or otherwise allowing others to use the account in any form. If the company discovers or has reasonable grounds to believe that the user is not the initial registrant of the account, in order to protect the security of the account, the company has the right to immediately suspend or terminate the provision of services to the registered account, or cancel the account. </p>
		<p>3.5 You are responsible for maintaining the security and confidentiality of your personal account and password, and bear full legal responsibility for the activities you engage in in the name of your registered account, including but not limited to any data modifications you make on "Whistle" , speech publishing, payment and other operational behaviors. You should attach great importance to the confidentiality of your account number and password, and do not disclose your account number and password to others under any circumstances. If you find that someone else is using your account without permission or any other security breach occurs, you should notify the company immediately. </p>
		<p>3.6 After losing or forgetting the password of your account, you can follow the company's appeal channel to promptly appeal and request to retrieve your account or password. You understand and acknowledge that the company's password retrieval mechanism only needs to identify that the information filled in the complaint form is consistent with the system record information, but cannot identify whether the complainant is the real authorized user of the account. The company specifically reminds you to keep your account number and password properly. When you are done using it, you should exit safely. Your account may be stolen or your password may be lost due to your own reasons such as improper storage or other force majeure factors, and you shall be solely responsible for this. </p>
		<p>3.7 When registering, using and managing an account, you should ensure the authenticity of the identity information filled in when registering the account. Please use true, accurate, legal and valid relevant identity certification materials and necessary documents when registering and managing the account. Information (including but not limited to your name and email address, contact number, contact address, etc.). In accordance with national laws and regulations, in order to use some functions of this software and services, you need to fill in your true identity information. Please complete real-name authentication in accordance with relevant laws and regulations, and pay attention to updating the above-mentioned relevant information in a timely manner. If the materials you submit or the information you provide are inaccurate, untrue, non-standard, illegal or the company has reason to suspect that they are wrong, untrue or illegal, the company has the right to refuse to provide you with relevant services or you may not be able to Use "Whistle" software and related services or some functions may be restricted during use. </p>
		<p>3.8 In addition to registering a "Whistle" account by yourself, you can also choose to use authorization to use other software user accounts that you legally own, including but not limited to the company and/or its affiliated companies, as well as third-party software or platform users registered with real names. Register an account and log in to use "Whistle" software and related services, except where this is restricted or prohibited by third-party software or platforms. When you log in with an existing account mentioned above, you should ensure that the corresponding account has been registered with your real name and agree to apply the relevant provisions in this agreement. </p>
		<p>3.9 You understand and agree that in addition to logging in and using the "Whistle" software and related services, you can also use your "Whistle" account to log in and use other software and services provided by the company, its affiliates or other partners. If you log in with the "Whistle" account and use the aforementioned services, you shall also be bound by the "User Agreement" and other agreement terms of other software and service providers. </p>
		<p>3.10 In order to improve the exposure and publishing efficiency of your content, you agree that your account in this software/website and all content published by the corresponding account authorize the company to automatically publish it to the company and/or affiliated companies using your account. A series of client software and websites operated. Your operations of publishing, modifying, and deleting content on this software/website will be synchronized to the above series of client software and websites. <br>When you log in to a series of client software products and websites (if any) operated by the company and/or affiliated companies through a registered or synchronized account, you should abide by the "User Agreement" and other agreements of the software product and website itself. provisions of the terms. </p>
		<p>3.11 If your account is stolen or lost due to malicious attacks by others, your own reasons, or other force majeure factors, you are solely responsible for it, and the company does not assume any responsibility. </p>
		<p>3.12 You understand that in order to make full use of account resources, if you fail to log in for the first time in time after registration or do not log in to an idle account for more than two consecutive months, the company has the right to withdraw your account at any time. If your account is withdrawn, you may not be able to log in and use the "Whistle" software and related services through the account you previously held, and any personalized settings and usage records saved under your account will not be restored. </p>
		<p>Before withdrawing your account, the company will prompt you in an appropriate manner. If you fail to log in and use your account within a certain period of time after receiving the relevant prompt, the company will withdraw the account. </p>
		<p>If your account is withdrawn, you can log in and use "Whistle" software and related services by registering a new account. Your behavior of registering a new account, logging in, and using it will still be subject to the relevant terms of this Agreement. </p>
		
		<h3>4. Protection of user personal information</h3>

		<p>4.1 We attach great importance to the protection of user information, and protecting users’ personal information is one of the company’s basic principles. The company is committed to protecting users' personal information (i.e., information that can identify users independently or combined with other information) together with users. </p>
		<p>4.2
		In the process of using "Whistle" software and related services, you may need to provide your personal information (including but not limited to your name, phone number, location information, device information, etc.) so that the company can provide you with better services. and corresponding technical support. You understand and agree that the company has the right to collect, use (including commercial cooperation use), store and share your personal information on the premise of complying with laws and regulations, this agreement and the "Whistle" Privacy Policy. At the same time, we will comply with the law. Protect your rights to browse, modify, delete relevant personal information and withdraw consent.
		</p>
		<p>4.3 You can browse and modify the personally identifiable information you submitted at any time. You understand and agree that for security and identification reasons (such as account or password retrieval and appeal services, etc.), you may not be able to modify the information provided during registration. Initial registration information and other verification information. </p>
		<p>4.4 The company will use security technology and other security measures that match the "Whistle" software and related services, and establish a complete management system to protect your personal information and prevent your information from being used improperly or without authorization. access, use or disclosure. </p>
		<p>4.5 The company will not disclose your personal information to any company, organization or individual outside the company without your consent, except in the following circumstances:</p>
		<p>
		(1) Obtain your explicit authorization and consent in advance;<br>
		(2) You share, transfer and disclose it to other companies, organizations and individuals on your own;<br>
		(3) Directly related to national security, national defense security, public safety, public health, and public interests;<br>
		(4) Disclosure or provision when necessary in accordance with the requirements of applicable laws and regulations, mandatory administrative and judicial requirements, or directly related to criminal investigation, prosecution, trial and judgment execution, etc. Under the premise of complying with laws and regulations, when the company receives the aforementioned request to disclose information, the company will require the other party to issue corresponding legal documents, such as a subpoena or a letter of investigation. The company will carefully review all requests to ensure that they have legal basis and are limited to data that administrative and judicial departments have the legal right to obtain for specific investigation purposes;<br>
		(5) To the extent permitted by laws and regulations, it is necessary to maintain the legitimate rights and interests of other "Whistle" users, the company and its affiliates, control the company's life, property, etc., or the safe and stable operation of rights protection products or services, such as search, Prevent and deal with illegal activities such as fraud and reduce credit risks (excluding violating the commitments made in this agreement and disclosing or providing personal information for profit purposes);<br>
		(6) Personal information from legally publicly disclosed information, such as legal news reports, government information disclosure and other channels;<br>
		(7) Other situations stipulated by laws and regulations. <br>
		</p>
		<p>4.6 Please be careful not to disclose your various property accounts, bank cards, credit cards, third-party payment accounts and corresponding passwords and other important information when using the "Whistle" software and related services, otherwise you may suffer any losses caused by this. It is your sole responsibility. </p>
		<p>4.7 Consider that the "Whistle" software and related services may include or be linked to information or other services (including websites) provided by third parties. You know and understand that third parties operating such services may ask you to provide personal information. The company specifically reminds you that you need to carefully read the user agreements, privacy policies and other relevant terms of these third parties, properly protect your personal information, and only provide it to these third parties when necessary. This Agreement (and other agreements and rules related to the "Whistle" software and related services (including but not limited to the "Whistle" Privacy Policy)) does not apply to services provided by any third party. We do not assume any legal responsibility for the consequences that may arise from the personal information provided.<br>For more information on the protection of user personal information, please see the "Whistle" Privacy Policy.</p>
				
		<h2>5. User Code of Conduct</h2>
		
		<h3>5.1 User behavior requirements</h3>
		<p>You are responsible for your use of this product and related services. Unless permitted by law or with the company's prior written permission, you must not engage in the following behaviors when using "Whistle" software and related services:</p>
			
		<p>5.1.1 Use any plug-ins, plug-ins, systems or third-party tools that are not authorized or permitted by the company to interfere, destroy, modify or otherwise affect the normal operation of the "Whistle" software and related services. </p>
			
		<p>5.1.2 Use or target "Whistle" software and related services to conduct any behavior that endangers computer network security, including but not limited to:</p>
		<ul>
		<li>(1) Illegal intrusion into other people’s networks, interference with normal functions of other people’s networks, theft of network data and other activities that endanger network security;</li>
		<li>(2) Provide programs and tools specifically used to engage in activities that endanger network security, such as intruding into the network, interfering with normal network functions and protective measures, stealing network data, etc.;</li>
		<li>(3) Knowing that others are engaged in activities that endanger network security, provide them with technical support, advertising promotion, payment and settlement, etc.;</li>
		<li>(4) Use unauthorized data or access unauthorized servers/accounts;</li>
		<li>(5) Entering public computer networks or other people’s computer systems without permission and deleting, modifying, or adding stored information;</li>
		<li>(6) Attempting to probe, scan, test the weaknesses of the "Whistle" system or network without permission, or perform other acts that undermine network security;</li>
		<li>(7) Attempting to interfere with or disrupt the normal operation of the "Whistle" system or website, intentionally spreading malicious programs or viruses, and other behaviors that disrupt and interfere with normal network information services;</li>
		<li>(8) Forge TCP/IP packet name or part of the name. </li>
		</ul>
	
		<p>5.1.3 Reverse engineer, reverse assemble, compile or otherwise attempt to discover the source code of the "Whistle" software. </p>
			
		<p>5.1.4 Malicious registration of “Whistle” accounts, including but not limited to frequent and batch registration of accounts. </p>
			
		<p>5.1.5 Violate laws, regulations, this agreement, the company's relevant rules and other behaviors that infringe on the legitimate rights and interests of others. </p>
			
		<p>In any case, if the company has reason to believe that any of your behavior violates or may violate the above agreement, the company can independently judge and handle it, and has the right to terminate the service to the user at any time without any prior notice. Provide services and hold them accountable. </p>
			
		<h3>5.2 Information content specifications</h3>
		<p>5.2.1 After completing the real-name authentication as required, users can log in to “Whistle” with their registered account or “Whistle” cooperation platform account to publish content, post comments, etc. </p>
			
		<p>5.2.2 The company is committed to making comments a civilized, rational, friendly and high-quality exchange of opinions. While promoting the development of the comment business, we will continue to strengthen the corresponding information security management capabilities, improve the self-discipline of comments, effectively fulfill social responsibilities, abide by national laws and regulations, respect the legitimate rights and interests of citizens, and respect social order and good customs. </p>
			
		<p>5.2.3 The content produced, commented, published, and disseminated by users (including but not limited to unpublished private videos uploaded to the "Whistle" platform, and snapshots with a 72-hour display period after publication) should consciously abide by laws and regulations , socialist system, national interests, citizens' legitimate rights and interests, social public order, morals and information authenticity, etc. "Seven bottom lines" requirements, otherwise the company will immediately take corresponding measures. Users are not allowed to post the following information:</p>
		<ul>
		<li>(1) Opposing the basic principles established by the Constitution;</li>
		<li>(2) Endangering national security and leaking state secrets;</li>
		<li>(3) Subverting state power, overthrowing the socialist system, inciting to split the country, and undermining national unity;</li>
		<li>(4) Damaging national honor and interests;</li>
		<li>(5) Promoting terrorism and extremism;</li>
		<li>(6) Promoting ethnic hatred and discrimination and undermining ethnic unity;</li>
		<li>(7) Inciting regional discrimination and hatred;</li>
		<li>(8) Undermining national religious policies and promoting cults and superstitions;</li>
		<li>(9) Fabricating and spreading rumors and false information to disrupt social order and undermine social stability;</li>
		<li>(10) Spreading or disseminating obscenity, pornography, gambling, violence, murder, terror or instigating crimes;</li>
		<li>(11) Endangering network security or using the Internet to endanger national security, honor and interests;</li>
		<li>(12) Insulting or defaming others and infringing upon others’ legitimate rights and interests;</li>
		<li>(13) Intimidating and threatening others with violence, and conducting human flesh searches;</li>
		<li>(14) Involving other people’s privacy, personal information or data;</li>
		<li>(15) Spreading dirty words and damaging social order and good morals;</li>
		<li>(16) Content that infringes upon other people’s privacy, reputation, portrait, intellectual property and other legitimate rights and interests;</li>
		<li>(17) Disseminate commercial advertisements, or similar commercial solicitation information, excessive marketing information and spam information;</li>
		<li>(18) Comments in languages other than those commonly used on this website;</li>
		<li>(19) Has nothing to do with the information being commented on;</li>
		<li>(20) The information published is meaningless, or character combinations are deliberately used to evade technical review;</li>
		<li>(21) Violating the legitimate rights and interests of minors or harming the physical and mental health of minors;</li>
		<li>(22) Secretly photographing or recording others without their permission, infringing upon others’ legal rights;</li>
		<li>(23) Contents that contain horror, violence, gore, high risk, or endanger the physical and mental health of the performer or others, including but not limited to the following situations:</li>
		<ul>
		<li>1) Any violent and/or self-harming content;</li>
		<li>2) Any content that threatens life and health or uses knives and other dangerous instruments to perform that endangers the personal and/or property rights of oneself or others;</li>
		<li>3) Content that encourages or induces others to participate in dangerous or illegal activities that may cause personal injury or death. </li>
		</ul>
		<li>(24) Other information that violates laws, regulations, policies, public order and good customs, interferes with the normal operation of "Whistle", or infringes upon the legitimate rights and interests of other users or third parties. </li>
		</ul>
		
		<h2>6. "Whistle" data usage specifications</h2>
				
		<p>6.1 Without the written permission of the company, users shall not, on their own or authorize, allow or assist any third party to conduct the following actions on the information content in the "Whistle" software and related services in this Agreement:</p>
		<ul>
		<li>(1) Copy, read, and use the information content of "Whistle" software and related services for commercial purposes including but not limited to promotion, increasing readings, pageviews, etc.;</li>
		<li>(2) Edit, organize, and arrange the information content of "Whistle" software and related services without authorization and then display it in channels other than the source pages of Whistle software and related services;</li>
		<li>(3) Use any form of identification method, including but not limited to special logos, special codes, etc., to generate traffic, read volume, guide, transfer, or transfer the information or content of "Whistle" software and related services on your own or by assisting third parties. Hijacking and other adverse effects;</li>
		<li>(4) Other acts of illegally obtaining information content of "Whistle" software and related services. </li>
		</ul>
			
		<p>6.2 With the written permission of the company, users' sharing and forwarding of information and content of "Whistle" software and related services should also comply with the following specifications:</p>
		<ul>
		<li>(1) The relevant data such as hot search words, hit rate, classification, search volume, click rate, and reading volume obtained by crawling, counting, and obtaining shall not be used in any way without the company's prior written consent. Publicize, provide, or disclose to any third party;</li>
		<li>(2) No changes in any form shall be made to the source webpages of the "Whistle" software and related services, including but not limited to the home page link entrances of the "Whistle" software and related services, nor may any changes be made to the "Whistle" software and related services. The display of the source page of the service shall not be obstructed in any form by blocking, inserting, pop-up windows, etc.;</li>
		<li>(3) Safe, effective, and strict measures should be taken to prevent the information content of the "Whistle" software and related services from being illegally obtained by third parties through any form, including but not limited to "spider" programs; < /li>
		<li>(4) Relevant data content shall not be used for purposes beyond the scope of the company's written permission, conduct any form of sales and commercial use, or disclose, provide or allow third parties to use it in any way; </li> li>
		<li>(5) Users’ behavior of sharing, forwarding, and copying “Whistle” software and related service information content to any third party shall also comply with other norms and standards established by the company, such as the Community Self-Discipline Convention and “Whistle” Related protocols for other functions. </li>
		</ul>
	
		<h2>7. Breach handling</h2>
				
		<p>7.1 In response to your violation of this agreement or other terms of service, the company has the right to independently judge and take advance warning, refuse to publish, immediately stop transmitting information, delete posts, audio, video and other content, ban speech for a short period, Measures such as restricting some or all functions of the account or permanently closing the account. The company has the right to announce the results of the processing and decide whether to resume use based on the actual situation. Relevant records will be kept for suspected violations of laws and regulations, or suspected illegal crimes, and will be reported to the relevant competent authorities in accordance with the law and cooperate with the investigations of the relevant competent authorities. The company reserves the right not to return deleted content. </p>
		<p>7.2 If a third party complains or makes a claim due to your violation of this Agreement or other terms of service, you shall bear all legal responsibilities. If the company and its affiliates, controlling companies, and successor companies make compensation to any third party or are punished by state agencies due to your illegal or breach of contract, you shall also fully compensate the company and its affiliates, controlling companies, and successor companies for any losses suffered as a result. of total losses. </p>
		<p>7.3 The company respects and protects the intellectual property rights, reputation rights, name rights, privacy rights and other legitimate rights and interests of legal persons and citizens. You guarantee that the text, video, audio, links, etc. uploaded when using the "Whistle" software and related services will not infringe any third party's intellectual property rights, reputation rights, name rights, privacy rights and other rights and legitimate rights and interests. Otherwise, the company has the right to remove the allegedly infringing content upon receipt of notification from the rights holder or relevant party. You shall bear all legal responsibilities for all claims made by third parties; if your infringement causes the company and its affiliates, controlling companies, and successor companies to suffer losses (including but not limited to economic, goodwill, etc. losses) , you should also fully compensate the company and its affiliates, controlling companies, and successor companies for all losses suffered. </p>
		<h2>8. Change, interruption and termination of services</h2>
		<p>8.1 You understand and agree that the "Whistle" software and related services provided by the company are provided according to the status quo that the existing technology and conditions can achieve. The company will do its best to provide you with services and ensure service continuity and security. You understand that the company cannot foresee and prevent legal, technical and other risks at any time, including but not limited to force majeure, viruses, Trojans, hacker attacks, system instability, third-party service defects and other intrusions from various security issues. Service interruption, data loss and other losses and risks. </p>
		<p>8.2 You understand and agree that, in order to meet the needs of the overall operation of the service, the company has the right to modify, interrupt, suspend or terminate the "Whistle" software and related services after announcement and notice, without being responsible or liable to the user for any compensation. </p>
		<h2>9. Advertising</h2>
		<p>9.1 When you use the "Whistle" software and related services, you fully understand and agree that: this service may include information, advertising or brand promotion services launched by the company for individuals or enterprises, and you agree that the company has the right to use "Whistle" "Whistle" software and related services and/or third-party suppliers and partners display commercial advertisements, promotions or information (including commercial or non-commercial information) in the software and related services. </p>
		<p>9.2 If you do not agree with the advertisement, you have the right to choose to turn off the advertising information; for the "Whistle" push notification service, you have the right to turn off the service yourself or stop using the "Whistle" software and related services. </p>
		<p>9.3 The company performs its advertising and promotion-related obligations in accordance with legal provisions. You should judge the authenticity and reliability of the advertising or promotional information yourself and be responsible for your own judgment. Unless expressly provided by laws and regulations, the user shall be solely responsible for any purchase or transaction you make due to such advertising or promotional information, or any damage or loss suffered due to the foregoing content, and the company shall not be held liable. </p>
			
		<h2>10. Intellectual property rights</h2>
		<p>10.1 The content (including but not limited to software, technology, programs, web pages, text, pictures, images, audio, video, charts, layout design, electronic documents, etc.) provided by the company in the "Whistle" software and related services Intellectual property rights belong to the company. The copyright, patent rights and other intellectual property rights of the software on which the Company provides this service are owned by the Company. Without the permission of the company, no one may use it without permission (including but not limited to monitoring, copying, disseminating, displaying, mirroring, uploading, and downloading the content in the "Whistle" software and related services through any robot, spider, etc. programs or devices). </p>
		<p>10.2 You understand and agree that the text, pictures, videos, audios, etc. posted and uploaded when using the "Whistle" software and related services are all original by you or have been legally authorized (including sub-licensed). The intellectual property rights of any content you upload or publish through "Whistle" belong to you or the original copyright owner. </p>
		<p>10.3 You know, understand and agree that the content you publish and upload through "Whistle" (including but not limited to text, pictures, videos, audios, musical works, sounds, dialogues included in videos and/or audios, etc.), grants The company and its affiliates, controlling companies, and successor companies have a worldwide, free, non-exclusive, sublicensable (through multiple levels) rights (including but not limited to reproduction rights, translation rights, assembly rights, and information network dissemination rights) , adaptation rights, production of derivatives, etc.), the scope of use includes but is not limited to current or other websites, applications, products or terminal devices, etc. You hereby acknowledge and agree that the grant of the foregoing rights includes the use and other exploitation of the Content (in whole or in part) in any promotion, advertising, marketing and/or publicity related to the Content, "Whistle", the Company and/or the Company's brand ) rights and license. For the avoidance of doubt, you agree that the grant of the above rights includes the use, reproduction and display of your personal image, likeness, name, trademarks, service marks, brands, names, logos and company marks ( if any) and any other branding, marketing or promotional assets. </p>
		<p>10.4 You confirm and agree to authorize the company to use its own name or entrust a professional third party to defend the rights on your behalf against content that infringes your intellectual property rights uploaded and published. The rights protection forms include but are not limited to: monitoring infringements, sending rights protection letters, When filing a lawsuit, arbitration, mediation, settlement, etc., the company has the right to make decisions on rights protection matters and implement them independently. </p>
		<p>10.5 The company provides technical support for the development and operation of "Whistle" and has all rights to all data and information generated during the development and operation of "Whistle" software and related services. </p>
		<p>10.6 Please do not use any of the company's trademarks, service marks, trade names, domain names, website names or other distinctive brand features (hereinafter collectively referred to as "Whistle") without permission under any circumstances. logo"). Without the Company’s prior written consent, you may not display, use or apply for registered trademarks, domain name registration, etc., in any manner, alone or in combination with the aforementioned logos in these Terms, nor may you express or imply to others the right to display, use, or otherwise The right to process these signs. If you use the above-mentioned trademarks, logos, etc. of the company in violation of this agreement and cause losses to the company or others, you shall bear all legal liability. </p>
				
		<h2>11. Disclaimer</h2>
		<p>11.1 You understand and agree that "Whistle" software and related services may be affected or interfered with by a variety of factors, and the company does not guarantee (including but not limited to):</p>
		<p>11.1.1 The company is fully suitable for the user’s requirements;</p>
		<p>11.1.2 The Company will be uninterrupted, timely, secure, reliable or error-free; any software, services or other materials obtained by the user through the Company will meet the user's expectations;</p>
		<p>11.1.3 Any errors in the software will be corrected. </p>
		<p>11.2 Users should treat with caution and make their own judgments regarding online information, account passwords, advertising or promotions suspected of borrowing money or other property-related information. Based on the aforementioned reasons, you may suffer losses in profits, business reputation, data or other information. The company shall not be liable for any direct, indirect, incidental, special, consequential or punitive damages for tangible or intangible losses. </p>
		<p>11.3 Users understand and agree that in the process of using the "Whistle" software and related services, they may encounter factors such as force majeure (force majeure refers to objective events that cannot be foreseen, cannot be overcome and cannot be avoided), including but not limited to government actions , natural disasters, cyber reasons, hacker attacks, wars or any other similar events. When force majeure occurs, the company will strive to repair it as soon as possible. However, if the force majeure causes losses to the user, the user agrees that the company will not be held responsible. </p>
		<p>11.4 The company has the right to deal with illegal content in accordance with this agreement. This right does not constitute the company’s obligations or commitments. The company cannot guarantee that illegal activities will be discovered in a timely manner or dealt with accordingly. </p>
		<p>11.5 The user clearly understands and agrees that the company does not provide any express or implied guarantee or condition of any kind regarding the services under this agreement, including but not limited to commercial merchantability, suitability for specific purposes, etc. Your use of the software and related services under this Agreement must be at your own risk. </p>
		<p>11.6 The user clearly understands and agrees that this agreement is to ensure compliance with national laws and regulations, maintain public order and good customs, and protect the legitimate rights and interests of others. The company will do its best to make judgments in accordance with relevant laws and regulations within its capabilities, but it does not guarantee the company’s judgment. It is completely consistent with the judgment of judicial and administrative agencies. Users have understood and agreed to bear the consequences of this. </p>
				
		<h2>12. Special agreements regarding individual services</h2>
		<p>12.1 "Whistle" software and related services contain information or information content links obtained by the company in various legal ways, and also include other individual services legally operated by the company and its affiliates. These services may exist as separate sections on "Whistle". The company reserves the right to add, reduce or change the settings and services of these special sections from time to time. </p>
		<p>12.2 You can enable and use the above individual service functions in the "Whistle" software. Certain individual services may require you to also accept a specially formulated agreement for that service or other rules that govern the relationship between you and the service provider. The company will provide these agreements and rules in a conspicuous manner for your review. Once you start using the above services, you will be deemed to be bound by the relevant agreements and rules regarding individual services. </p>
		<p>12.3 If you may use software and related services provided by a third party in the "Whistle" software, in addition to complying with this agreement and other relevant rules in the "Whistle" software, you should also abide by the third party's agreement and relevant rules. . Any disputes, losses or damages arising from third-party software and related services shall be resolved by you and the third party. </p>
				
		<h2>13. Terms of use for minors</h2>
		<p>13.1 If you are a minor under the age of 18, you should read and agree to this agreement under the supervision and guidance of your parents or other guardians before using the "Whistle" software and related services. </p>
		<p>13.2 The company attaches great importance to the protection of the personal information of minors. When minors fill in personal information, please strengthen their awareness of personal protection and treat it with caution, and use the "Whistle" software and related services correctly under the guidance of their guardians. </p>
		<p>13.3 Underage users understand that if you violate laws, regulations, and the contents of this Agreement, you and your guardians shall bear all consequences resulting therefrom in accordance with legal provisions. </p>
		<p>13.4 Special Tips for Minor Users</p>
		<p>13.4.1 Teenagers who use "Whistle" software and related services should learn to use the Internet correctly within a reasonable range under the supervision and guidance of their guardians, avoid being addicted to virtual cyberspace, and develop good Internet habits. </p>
		<p>13.4.2 Teenage users must abide by the "National Youth Internet Civilization Convention":</p>
		<ul>
		<li>(1) Be good at online learning and avoid browsing bad information;</li>
		       <li>(2) Communicate honestly and friendly, and do not insult or deceive others; </li>
		       <li>(3) Enhance the awareness of self-protection and avoid casually dating netizens;</li>
		       <li>(4) Maintain network security and not disrupt network order;</li>
		<li>(5) It should be beneficial to physical and mental health and not indulge in virtual time and space. </li>
		</ul>
		<p>13.4.3 In order to better protect the privacy rights of minors, the company reminds users to be careful when publishing content containing minors’ materials. Once published, the user will be deemed to have agreed to “Whistle” displaying the minors’ information, portraits, sounds, etc., and allows "Whistle" to use and process such content related to minors in accordance with this agreement. </p>
				
		<h2>14. Others</h2>
		<p>14.1 The establishment, effectiveness, performance, interpretation and resolution of disputes of this agreement shall be governed by the laws of the mainland of the People's Republic of China. If any provision of this Agreement is invalid due to conflict with the laws of the mainland of the People's Republic of China, these provisions will be reinterpreted as close to the original meaning of this Agreement as possible, and other provisions of this Agreement shall still have full force and effect. </p>
		<p>14.2 The signing place of this agreement is Haidian District, Beijing City, People's Republic of China. If there is a dispute between you and the company, both parties should try their best to resolve it through friendly negotiation. If the negotiation fails, you agree that the dispute should be submitted to the jurisdiction of the People's Court of Haidian District, Beijing City. . </p>
		<p>14.3 The company has the right to modify this agreement based on changes in national policies, technical conditions, product functions, etc., and the company will publish the revised agreement. Once the foregoing content is officially released, the company will remind you of the updated content in appropriate ways (official website announcement, system notification, etc.) so that you can keep abreast of the latest version of this Agreement. The revised content will form an integral part of this Agreement and you shall abide by it as well. If you have any objection to the revised agreement, please immediately stop logging in and using the "Whistle" software and related services. If you log in or continue to use the "Whistle" software and related services, it will be deemed that you agree with the revised agreement. </p>
		<p>14.4 The headings in this Agreement are for convenience and reading only and do not affect the meaning or interpretation of any provision of this Agreement. </p>
		<p>14.5 You and the company are independent entities. Under no circumstances shall this agreement constitute any form of express or implied guarantee or condition by the company to the user, nor shall there be an agency, partnership, joint venture or employment relationship between the two parties. </p>
		<p>14.6 The copyright of this Agreement belongs to the Company, and the Company reserves all rights of interpretation and modification within the scope permitted by law. </p>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				options: [
					{
						value: '中文',
						label: '中文'
					}, {
						value: 'English',
						label: 'English'
					}, {
						value: '한국인',
						label: '한국인'
					}, {
						value: '日本語',
						label: '日本語'
					}
				],
				value: '',
				language: ''
			}
		},
		created() {
			// url获取参数
			this.language = this.$route.query.language;
			if (this.language == 'zh_CN') {
				this.$router.push({
					path: '/UserServiceAgreementZh'
				})
			}
			if (this.language == 'en_US') {
				this.$router.push({
					path: '/UserServiceAgreementEn'
				})
			}
		},
		methods: {
			clickChange(value) {
				this.value = value;
				if (value == '中文') {
					this.$router.push({
						path: '/UserServiceAgreementZh'
					})
				}
				if (value == 'English') {
					this.$router.push({
						path: '/UserServiceAgreementEn'
					})
				}
				if (value == '한국인') {
					this.$router.push({
						path: '/UserServiceAgreementKo'
					})
				}
				if (value == '日本語') {
					this.$router.push({
						path: '/UserServiceAgreementJa'
					})
				}
			},
		}
	}
</script>

<style>
	.container {
		padding: 0.1rem 0.3rem;		
	}
	
	.lang {
		text-align: right;
		margin-bottom: 0.3rem;
	}

	h1,
	h2 {
		color: #333;
		font-size: 0.5rem;
	}

	h3 {
		margin-top: 20px;
		font-size: 0.4rem;
	}

	p {
		margin-bottom: 15px;
		font-size: 0.3rem;
	}
	
	li {
		font-size: 0.3rem;
	}
</style>